// import './App_base.css';
// import './App_piperwai.css';
import './App_drloretta.css';
// import './App_possible.css';
// import './App_shiseido.css';
import React, { useState, useEffect, useRef } from 'react';
import Navigation from './Navigation';
// import AudioVisualizer from './AudioVisualizer';
import ChatInterface from './ChatInterface';
import LoginPage from './LoginPage';
// import VideoPlayer from './VideoPlayer';
import { websiteURL } from "./constants";
// import shiseido_message_backdrop from './shiseido_message_backdrop.png';
import jenn_avatar from './jenn_avatar.png';
// import shi_video from './ashley_video2.mp4';
// import shi_video from './visualizer2.mov';
// import shi_video from './shiseido_dani_products.mp4';

const App = () => {
  const [username, setUsername] = useState(null);
  const [token, setToken] = useState(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [showChat, setShowChat] = useState(false);
  const [isVideo1Playing, setIsVideo1Playing] = useState(true);
  const [isVideo1Active, setIsVideo1Active] = useState(true);
  const video2Ref = useRef(null);
  const [videoIndex, setVideoIndex] = useState(-1);
  // const [videos, setVideos] = useState(['ashley message 1.mp4', 'ashley message 2.mp4', 'ashley message 3.mp4', 'ashley message 4.mp4', 'ashley message 5.mp4', 'ashley message 6.mp4', 'ashley message 7.mp4']);
  // const [videos, setVideos] = useState(['matilda message 1.mp4', 'matilda message 2.mp4', 'matilda message 3.mp4', 'matilda message 4.mp4', 'matilda message 5.mp4', 'matilda message 6.mp4', 'matilda message 7.mp4']);
  // const [videos, setVideos] = useState(['matilda the jenn 1.mp4', 'matilda the jenn 2.mp4', 'matilda the jenn 3.mp4', 'matilda the jenn 4.mp4', 'matilda the jenn 5.mp4', 'matilda the jenn 6.mp4', 'matilda the jenn 7.mp4']);
  const [videos, setVideos] = useState(['the jenn transparent silence.mp4', 'matilda the jenn 2.mp4', 'matilda the jenn 3.mp4', 'matilda the jenn 4.mp4', 'matilda the jenn 5.mp4', 'matilda the jenn 6.mp4', 'matilda the jenn 7.mp4']);
  const videoRefs = useRef(videos.map(() => React.createRef()));

  // const handleVideoSwitch = (video) => {
  //   setIsVideo1Active(false);
  // };

  // const handleVideo2End = () => {
  //   setIsVideo1Active(true);
  // };

  // const handleVideoSwitch = () => {
  //     setIsVideo1Active(false);
  //     const nextIndex = (videoIndex + 1) % videos.length;
  //     if(videoIndex != -1){
  //       videoRefs.current[videoIndex].current.style.display = 'none';
  //     }
  //     // videoRefs.current[nextIndex].current.style.display = 'block';
  //     videoRefs.current[nextIndex].current.style.display = 'none';
  //     videoRefs.current[nextIndex].current.play();
  //     setVideoIndex(videoIndex + 1);
  // };

  // const handleVideoEnd = () => {
  //   console.log('video ended');
  //     setIsVideo1Active(true);
  // };

  const handleUser = (user, tok) => {
    console.log(user)
    console.log(tok)
    setUsername(user)
    setToken(tok)
  }

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (username === null) {
    return <LoginPage onLogin={handleUser} />;
  } else {
    return (
      <div className="backdrop">
        {/*{<AudioVisualizer audioSrc={shi_video} />}*/}
        <iframe 
          src={websiteURL}
          style={{ width: '100%', height: '100vh', border: 'none' }}
          sandbox="allow-scripts allow-same-origin"
          title="External Website"
        />
        {/*<img className="message-background" src={shiseido_message_backdrop} />*/}
{/*        <VideoPlayer src={shi_video} />
          <video 
            // src="jenn_silence_30_seconds.mp4"
            src="the jenn silent.mp4"
            className="jenn-avatar-video"
            // autoPlay
            loop
            // style={{ display: isVideo1Active ? 'block' : 'none' }}
            style={{ display: isVideo1Active ? 'none' : 'none' }}
          />
          {videos.map((src, index) => (
              <video
                  key={src}
                  ref={videoRefs.current[index]}
                  className="jenn-avatar-video"
                  src={src}
                  // autoPlay={isVideo1Active === false && index === videoIndex}
                  // style={{ display: isVideo1Active === false && index === videoIndex ? 'block' : 'none' }}
                  style={{ display: isVideo1Active === false && index === videoIndex ? 'none' : 'none' }}
                  onEnded={() => handleVideoEnd()}
              />
          ))}*/}
        <div className={`${showChat ? 'chat-interface-wrapper' : ' '}`}>
          {/*<ChatInterface username={username} token={token} screenWidth={windowSize[0]} toggleChat={toggleChat} showChat={showChat} onVideoSwitch={handleVideoSwitch} video2Ref={video2Ref} />*/}
          <ChatInterface username={username} token={token} screenWidth={windowSize[0]} toggleChat={toggleChat} showChat={showChat} />
        </div>
      </div>
    )
  }
};

export default App;