export const API_POLLING_INTERVAL = 5000;
export const websiteURL = 'http://3.94.92.53:3003';
// export const websiteURL = 'http://localhost:3003';
export const URL = 'http://3.94.92.53:1000';
// export const URL = 'http://localhost:1000';
export const companyName = 'drloretta';
// export const companyName = 'shiseido';
// export const companyName = 'shiseido_demo';
// export const companyName = 'sokolin';
// export const companyName = 'piperwai';
